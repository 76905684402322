import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReporteSigo from "./components/Engines/ReporteSigo";
import ReporteSii from "./components/Engines/ReporteSii";
import Reportes from "./components/Reportes/";
import Productos from "./components/Productos";
import Locales from "./components/Locales";
import Proveedores from "./components/Proveedores";
import NavBar from "./components/Navigation/NavBar";
import Footer from "./components/Navigation/Footer";

function App() {
  return (
    <Router>
      <div>
        <NavBar />
        <Route exact path="/" component={ReporteSigo} />
        <Route path="/sigo" component={ReporteSigo} />
        <Route path="/sii" component={ReporteSii} />
        <Route path="/reportes" component={Reportes} />
        <Route path="/productos" component={Productos} />
        <Route path="/locales" component={Locales} />
        <Route path="/proveedores" component={Proveedores} />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
