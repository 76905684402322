import React from "react";
import MaterialTable from "material-table";
import axios from "axios";

class Proveedores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      columns: [
        { title: "Nombre", field: "nombre" },
        { title: "RUT", field: "rut" },
        { title: "C.Alimento", field: "cod_alimento" },
        { title: "C.Aseo", field: "cod_aseo" },
        { title: "C.Desechable", field: "cod_desechable" },
        {
          title: "C.Comisión",
          field: "cod_comision"
        },
        {
          title: "C.Admin",
          field: "cod_admin"
        },
        {
          title: "C.Arriendo",
          field: "cod_arriendo"
        },
        {
          title: "C.Libreria",
          field: "cod_libreria"
        },
        {
          title: "C.Fumigacion",
          field: "cod_fumigacion"
        },
        {
          title: "C.Seguridad",
          field: "cod_seguridad"
        },
        {
          title: "C.Gas",
          field: "cod_gas"
        }
      ],
      data: []
    };
  }
  fetchData = () => {
    this.setState({ isLoading: true });
    return axios.get(`/api/proveedores`).then(r => r.data);
  };

  componentDidMount = () => {
    this.fetchData().then(r => this.setState({ data: r, isLoading: false }));
  };

  addProducto = prod => {
    this.setState({ isLoading: true });
    return axios.post(`/api/proveedores`, prod).then(r =>
      this.setState({
        isLoading: false,
        data: [...this.state.data, r.data]
      })
    );
  };

  removeProducto = prodId => {
    return axios.delete(`/api/proveedores/${prodId}`).then(r => {
      this.setState({
        isLoading: false,
        data: this.state.data.filter(d => d._id !== r.data._id)
      });
    });
  };

  updateProducto = (newData, oldData) => {
    this.setState({ isLoading: true });
    return axios
      .put(`/api/proveedores/${oldData._id}`, {
        nombre: newData.nombre,
        rut: newData.rut,
        cod_alimento: newData.cod_alimento,
        cod_aseo: newData.cod_aseo,
        cod_desechable: newData.cod_desechable,
        cod_comision: newData.cod_comision,
        cod_admin: newData.cod_admin,
        cod_arriendo: newData.cod_arriendo,
        cod_libreria: newData.cod_libreria,
        cod_fumigacion: newData.cod_fumigacion,
        cod_seguridad: newData.cod_seguridad,
        cod_gas: newData.cod_gas
      })
      .then(r => {
        const data = this.state.data;
        const index = data.indexOf(oldData);
        data[index] = newData;
        this.setState({
          isLoading: false,
          data
        });
      });
  };

  render() {
    return (
      <MaterialTable
        isLoading={this.state.isLoading}
        options={{ pageSize: 20, pageSizeOptions: [5, 10, 20, 100] }}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "filas",
            labelRowsPerPage: "Filas por página",
            firstAriaLabel: "Primera página",
            firstTooltip: "Primera página",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Página siguiente",
            nextTooltip: "Página siguiente",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página"
          },
          toolbar: {
            nRowsSelected: "{0} fila(s) seleccionadas",
            searchTooltip: "Buscar",
            searchPlaceholder: "Buscar"
          },
          header: {
            actions: "Acciones"
          },
          body: {
            editRow: {
              cancelTooltip: "Cancelar",
              saveTooltip: "Guardar",
              deleteText: "Seguro de que quieres eliminar esta fila?"
            },
            emptyDataSourceMessage: "No hay registros",
            filterRow: {
              filterTooltip: "Filtro"
            }
          }
        }}
        title="Proveedores"
        columns={this.state.columns}
        data={this.state.data}
        editable={{
          onRowAdd: newData => this.addProducto(newData),
          onRowUpdate: (newData, oldData) =>
            this.updateProducto(newData, oldData),
          onRowDelete: oldData => this.removeProducto(oldData._id)
        }}
      />
    );
  }
}

export default Proveedores;
