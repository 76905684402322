import React, { useState, useEffect } from "react";
import { MDBBtn, MDBModal, MDBModalBody, MDBRow } from "mdbreact";
import { Link } from "react-router-dom";
const TopModal = (props) => {
  const [toggled, setToggled] = useState(props.isOpen);
  useEffect(() => {
    setToggled(props.isOpen);
  }, [props.isOpen]);

  return (
    <MDBModal position="top" isOpen={toggled} centered>
      <MDBModalBody>
        <MDBRow
          className="justify-content-center align-items-center"
          style={{ margin: 5 }}
        >
          <p className="pt-3 pr-2">
            Hay productos sin clasificar, favor diríjase a
            <Link to="/productos/"> Productos</Link> y clasifique los faltantes.
          </p>
          <Link to="/productos/">
            <MDBBtn color="warning" onClick={() => setToggled(!toggled)}>
              Ir a Productos
            </MDBBtn>
          </Link>
          <MDBBtn color="warning" onClick={() => setToggled(!toggled)}>
            Cerrar
          </MDBBtn>
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  );
};

export default TopModal;
