import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

const FooterPage = () => {
  return (
    <MDBFooter
      style={{ backgroundColor: "#ffcc00" }}
      className="font-small pt-4 mt-4"
    >
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol md="6">
            <h5 className="title">Conector SIGO-Softland</h5>
            <p>
              Integración automática de informes SIGO 2.0 y SII con Softland ERP
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright:{" "}
          <a href="https://www.solf.cl"> Soluciones Funcionales SPA. </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};

export default FooterPage;
