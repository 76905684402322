import React from "react";
import MaterialTable from "material-table";
import axios from "axios";

class Locales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      columns: [
        { title: "Empresa", field: "empresa" },
        { title: "RUT", field: "rut" },
        { title: "Local", field: "nombre" },
        { title: "C.Costo", field: "centro_costo" },
        { title: "C.SIGO", field: "codigo_sigo" },
        { title: "Marca", field: "marca" },
        { title: "C.Alimento", field: "cod_alimento" },
        { title: "C.Aseo", field: "cod_aseo" },
        { title: "C.Desechable", field: "cod_desechable" },
        { title: "C.Proveedor", field: "cod_proveedores" },
        { title: "C.IVA", field: "cod_iva" }
      ],
      data: []
    };
  }

  fetchData = () => {
    this.setState({ isLoading: true });
    return axios.get(`/api/locales`).then(r => r.data);
  };

  componentDidMount = () => {
    this.fetchData().then(r => this.setState({ data: r, isLoading: false }));
  };

  addProducto = prod => {
    console.log(prod);
    this.setState({ isLoading: true });
    return axios.post(`/api/locales`, prod).then(r =>
      this.setState({
        isLoading: false,
        data: [...this.state.data, r.data]
      })
    );
  };

  removeProducto = prodId => {
    return axios.delete(`/api/locales/${prodId}`).then(r => {
      this.setState({
        isLoading: false,
        data: this.state.data.filter(d => d._id !== r.data._id)
      });
    });
  };

  updateProducto = (newData, oldData) => {
    this.setState({ isLoading: true });
    return axios
      .put(`/api/locales/${oldData._id}`, {
        centro_costo: newData.centro_costo,
        cod_alimento: newData.cod_alimento,
        cod_aseo: newData.cod_aseo,
        cod_desechable: newData.cod_desechable,
        cod_iva: newData.cod_iva,
        cod_proveedores: newData.cod_proveedores,
        codigo_sigo: newData.codigo_sigo,
        empresa: newData.empresa,
        marca: newData.marca,
        nombre: newData.nombre,
        rut: newData.rut
      })
      .then(r => {
        const data = this.state.data;
        const index = data.indexOf(oldData);
        data[index] = newData;
        this.setState({
          isLoading: false,
          data
        });
      });
  };

  render() {
    return (
      <MaterialTable
        isLoading={this.state.isLoading}
        options={{ pageSize: 20, pageSizeOptions: [5, 10, 20, 100] }}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "filas",
            labelRowsPerPage: "Filas por página",
            firstAriaLabel: "Primera página",
            firstTooltip: "Primera página",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Página siguiente",
            nextTooltip: "Página siguiente",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página"
          },
          toolbar: {
            nRowsSelected: "{0} fila(s) seleccionadas",
            searchTooltip: "Buscar",
            searchPlaceholder: "Buscar"
          },
          header: {
            actions: "Acciones"
          },
          body: {
            editRow: {
              cancelTooltip: "Cancelar",
              saveTooltip: "Guardar",
              deleteText: "Seguro de que quieres eliminar esta fila?"
            },
            emptyDataSourceMessage: "No hay registros",
            filterRow: {
              filterTooltip: "Filtro"
            }
          }
        }}
        title="Locales"
        columns={this.state.columns}
        data={this.state.data}
        editable={{
          onRowAdd: newData => this.addProducto(newData),
          onRowUpdate: (newData, oldData) =>
            this.updateProducto(newData, oldData),
          onRowDelete: oldData => this.removeProducto(oldData._id)
        }}
      />
    );
  }
}

export default Locales;
