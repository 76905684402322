import React, { useState } from "react";
import { MDBBtn } from "mdbreact";

function ConfirmRow(props) {
  const [selectedOption, setSelectedOption] = useState(props.firstOption);
  const handleChange = e => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = e => {
    props.fillCodigo(props.row, selectedOption);
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        {props.row} - {props.nombre} - {props.folio}
        <select
          className="browser-default custom-select"
          value={selectedOption}
          onChange={handleChange}
        >
          {props.options.map(o => (
            <option value={o.value}>{o.text}</option>
          ))}
        </select>
      </label>
      <MDBBtn color="warning" type="submit">
        Guardar
      </MDBBtn>
    </form>
  );
}

export default ConfirmRow;
