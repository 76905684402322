import React, { useState, useEffect } from "react";
import {
  toast,
  ToastContainer,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBSelect,
  MDBInput,
} from "mdbreact";
import TopModal from "../../Navigation/Modal/TopModal";
import axios from "axios";
//Expires: new Date()
function ReporteSigo() {
  const [selecLocales, setSelecLocales] = useState([]);
  const [locales, setLocales] = useState([]);
  const [topModal, setTopModal] = useState(false);
  const [meses] = useState([
    { text: "Enero", value: "01" },
    { text: "Febrero", value: "02" },
    { text: "Marzo", value: "03" },
    { text: "Abril", value: "04" },
    { text: "Mayo", value: "05" },
    { text: "Junio", value: "06" },
    { text: "Julio", value: "07" },
    { text: "Agosto", value: "08" },
    { text: "Septiembre", value: "09" },
    { text: "Octubre", value: "10" },
    { text: "Noviembre", value: "11" },
    { text: "Diciembre", value: "12" },
  ]);
  const [selecMes, setSelectMes] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear().toString());

  useEffect(() => {
    axios
      .get(`/api/productos/incompletos/`)
      .then((r) => {
        if (r.data.length > 0) {
          setTopModal(true);
        }
      })
      .catch((e) => console.log(e));

    axios.get(`/api/locales/`).then((r) =>
      setLocales(
        r.data.map((l) => ({
          text: `${l.marca}-${l.nombre.toUpperCase()}`,
          value: `${l.marca}-${l.codigo_sigo}`,
        }))
      )
    );
  }, []);

  const submit = (e) => {
    console.log(year);
    e.preventDefault();
    toast.success("Reporte en proceso, visite 'Reportes'", {
      closeButton: false,
    });
    axios
      .post(`/api/reportes/sigo-compras`, {
        locales: selecLocales,
        mes: selecMes,
        year: year,
      })
      .then((r) => console.log(r))
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <TopModal isOpen={topModal} />
      <MDBContainer>
        <MDBRow>
          <MDBCol md="6">
            <form>
              <p className="h4 text-center mb-4 mt-4">Informe SIGO local V1</p>
              <MDBInput
                value={year}
                onChange={(e) => setYear(e.target.value)}
                label="Año"
              />
              <MDBSelect
                search
                options={meses}
                searchLabel="Buscar"
                selected="Seleccione mes"
                label="Seleccione mes"
                getValue={(e) => setSelectMes(e)}
              />
              <MDBSelect
                multiple
                search
                options={locales}
                label="Seleccione local"
                selected="Seleccione locales"
                getValue={(e) => setSelecLocales(e)}
                selectAll
                selectAllLabel="Seleccionar todos"
                searchLabel="Buscar"
              />
              <br />
              <div className="text-center mt-4">
                <MDBBtn color="warning" type="submit" onClick={submit}>
                  Enviar
                </MDBBtn>
              </div>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <ToastContainer
        hideProgressBar={true}
        newestOnTop={true}
        autoClose={5000}
      />
    </div>
  );
}

export default ReporteSigo;
