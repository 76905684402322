import React from "react";
import MaterialTable from "material-table";
import axios from "axios";
import moment from "moment";

class Reportes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      columns: [
        { title: "Local", field: "local" },
        { title: "Fecha Inicio", field: "fecha_inicio" },
        { title: "Fecha Fin", field: "fecha_fin" },
        { title: "Estado", field: "status" },
        {
          title: "Creado",
          field: "last_updated",
          render: rowData =>
            moment(rowData.last_updated).format("DD/MM/YYYY HH:mm")
        }
      ],
      data: []
    };
  }

  fetchData = () => {
    this.setState({ isLoading: true });
    return axios.get(`/api/reportes`).then(r => r.data);
  };

  componentDidMount = () => {
    this.fetchData().then(r => this.setState({ data: r, isLoading: false }));
  };

  addProducto = prod => {
    this.setState({ isLoading: true });
    return axios
      .post(`/api/reportes`, {
        nombre: prod.nombre,
        tipo: prod.tipo
      })
      .then(r =>
        this.setState({
          isLoading: false,
          data: [...this.state.data, r.data]
        })
      );
  };

  removeProducto = prodId => {
    this.setState({ isLoading: true });
    return axios.delete(`/api/reportes/${prodId}`).then(r =>
      this.setState({
        isLoading: false,
        data: this.state.data.filter(d => d._id !== prodId)
      })
    );
  };

  updateProducto = (newData, oldData) => {
    this.setState({ isLoading: true });
    return axios
      .put(`/api/reportes/${oldData._id}`, {
        nombre: newData.nombre,
        tipo: newData.tipo
      })
      .then(r => {
        const data = this.state.data;
        const index = data.indexOf(oldData);
        data[index] = newData;
        this.setState({
          isLoading: false,
          data
        });
      });
  };

  render() {
    return (
      <MaterialTable
        isLoading={this.state.isLoading}
        options={{ pageSize: 20, pageSizeOptions: [5, 10, 20, 100] }}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "filas",
            labelRowsPerPage: "Filas por página",
            firstAriaLabel: "Primera página",
            firstTooltip: "Primera página",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Página siguiente",
            nextTooltip: "Página siguiente",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página"
          },
          toolbar: {
            nRowsSelected: "{0} fila(s) seleccionadas",
            searchTooltip: "Buscar",
            searchPlaceholder: "Buscar"
          },
          header: {
            actions: "Acciones"
          },
          body: {
            editRow: {
              cancelTooltip: "Cancelar",
              saveTooltip: "Guardar",
              deleteText: "Seguro de que quieres eliminar esta fila?"
            },
            emptyDataSourceMessage: "No hay registros",
            filterRow: {
              filterTooltip: "Filtro"
            }
          }
        }}
        title="Reportes"
        columns={this.state.columns}
        data={this.state.data}
        actions={[
          {
            icon: "save",
            tooltip: "Descargar reporte",
            onClick: (event, rowData) => window.open(rowData.url)
          }
        ]}
      />
    );
  }
}

export default Reportes;
