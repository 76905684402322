import React, { useState, useEffect } from "react";
import MDBFileupload from "mdb-react-fileupload";
import { MDBCol, MDBContainer, MDBRow, MDBBtn, MDBSelect } from "mdbreact";
import axios from "axios";
import _ from "lodash";
import ConfirmRow from "./ConfirmRow";
import { CSVLink } from "react-csv";

function ReporteSii() {
  const [archivo, setArchivo] = useState();
  const [locales, setLocales] = useState([]);
  const [selecLocales, setSelecLocales] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState();
  const [rows, setRows] = useState([]);
  const [csvData, setCsvData] = useState();
  useEffect(() => {
    axios.get(`/api/locales/`).then(r =>
      setLocales(
        r.data.map(l => ({
          text: `${l.marca}-${l.nombre.toUpperCase()}`,
          value: `${l.marca}-${l.codigo_sigo}`
        }))
      )
    );
  }, []);

  const submit = () => {
    const formData = new FormData();
    formData.append("file", archivo);
    axios
      .post(`/api/reportes/upload`, formData)
      .then(r => {
        setRows(r.data);
      })
      .catch(err => console.log(err));
  };

  const confirm = () => {
    axios
      .post(`/api/reportes/confirm`, {
        json: rows,
        local: getCodSigo(selecLocales)
      })
      .then(r => {
        setCsvData(r.data);
      })
      .catch(err => console.log(err));
  };

  const getCodSigo = () => selecLocales[0].split("-")[1];

  const generateFileName = () => `${selectedLabel}.csv`;

  const generateErrors = () => {
    const incompletes = [];
    rows.forEach((r, i) => {
      if (Object.keys(r.codigos).length === 0) {
        var newObj = {};
        newObj = { row: i, rut: r.rut, folio: r.folio, nombre: r.nombre };
        incompletes.push(newObj);
      }
    });
    return _.uniqBy(incompletes, "rut");
  };

  const generateCorrections = () => {
    const incompletes = [];
    rows.forEach((r, i) => {
      if (typeof r.codigos === "object" && Object.keys(r.codigos).length > 1) {
        var newObj = {};
        newObj = {
          row: i,
          rut: r.rut,
          folio: r.folio,
          nombre: r.nombre,
          codigos: r.codigos
        };
        incompletes.push(newObj);
      }
    });
    return _.uniqBy(incompletes, "folio");
  };

  const fillCodigo = (row, value) => {
    //iterar las filas del state
    //reemplazar codigo ingresado por el usuario por el que esta actualmente
    const newObj = { ...rows[row], codigos: value };
    const oldArray = rows.filter((r, i) => i !== row);
    oldArray.splice(row, 0, newObj);
    setRows(oldArray);
  };

  const mapOptions = codigos => {
    const options = [];
    const fields = [
      "cod_aseo",
      "cod_alimento",
      "cod_desechable",
      "cod_comision",
      "cod_admin",
      "cod_arriendo",
      "cod_libreria",
      "cod_fumigacion",
      "cod_seguridad",
      "cod_gas"
    ];
    fields.forEach(f => {
      if (codigos[f]) {
        options.push({ text: f, value: codigos[f] });
      }
    });
    return options;
  };
  return (
    <MDBContainer fluid className="text-center text-md-left">
      {rows.length === 0 && (
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <MDBSelect
                search
                options={locales}
                label="Seleccione local"
                selected="Seleccione locales"
                getValue={e => setSelecLocales(e)}
                getTextContent={e => setSelectedLabel(e)}
                searchLabel="Buscar"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <label>Ingrese archivo excel de SII!</label>
              <MDBFileupload
                name="file"
                messageDefault="Arrastre archivo SII aqui o haga click para subir"
                messageReplace="Arrastre o click para reemplazar"
                messageRemove="Eliminar"
                messageError="Error"
                allowedFileExtensions={["xls", "csv", "xlsx"]}
                errorFileSize="Archivo supera el maximo de 1MB"
                errorFileExtension="Formatos permitidos: xls, csv, xlsx"
                maxFileSize="1M"
                getValue={v => setArchivo(v)}
              />
              <MDBBtn color="warning" onClick={submit}>
                Subir Excel
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      )}
      {generateErrors().length > 0 && (
        <MDBRow>
          <MDBCol md="6">
            <h2>Proveedores inexistentes o sin codigos asociados</h2>
            <MDBBtn color="warning" onClick={() => setRows([])}>
              Volver
            </MDBBtn>

            {generateErrors().map(i => (
              <div key={i.rut}>
                {i.rut} - {i.nombre}
              </div>
            ))}
          </MDBCol>
        </MDBRow>
      )}
      {generateCorrections().length > 0 && generateErrors().length === 0 && (
        <MDBRow>
          <MDBCol>
            <h2>Proveedores con multi codigo</h2>
            {generateCorrections().map(i => (
              <ConfirmRow
                folio={i.folio}
                rut={i.rut}
                nombre={i.nombre}
                fillCodigo={fillCodigo}
                codigos={i.codigos}
                row={i.row}
                options={mapOptions(i.codigos)}
                firstOption={mapOptions(i.codigos)[0].value}
              />
            ))}
          </MDBCol>
        </MDBRow>
      )}
      {generateCorrections().length === 0 &&
        generateErrors().length === 0 &&
        rows.length > 0 && (
          <MDBContainer>
            {confirm()}
            <h4>Informe listo para descargar</h4>
            {csvData && (
              <CSVLink data={csvData} filename={generateFileName()}>
                <MDBBtn color="warning">Descargar</MDBBtn>
              </CSVLink>
            )}
            <MDBBtn onClick={() => setRows([])} color="warning">
              <span>Volver</span>
            </MDBBtn>
          </MDBContainer>
        )}
    </MDBContainer>
  );
}

export default ReporteSii;
